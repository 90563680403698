import React from 'react';
import axios from 'axios';
import { FaPrescriptionBottle } from "react-icons/fa6";

function DeleteConfirmation({type, title, id, updateData}) {

    const fetchData = async () => {
        try {
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN
                },
              }
            let body = JSON.stringify({
                Id: id,
              })
            
            let uri = global.API_URI+'/dictation/'

            switch(type) {
                case 0:  
                uri+='delete'
                break
                
                case 1:
                uri+='question-delete'  
                break

                case 2:
                uri+='answer-delete'  
                break
              
                default:
                
                break
              }
            
            await axios.post(uri, body, config);
            updateData();
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

  const handleDelete = () => {
    // Отображаем окно подтверждения
    console.log(id)
    const isConfirmed = window.confirm('Вы уверены, что хотите '+title+'?');
    
    // Если пользователь нажал "ОК", выполняем удаление
    if (isConfirmed) {
      // Логика удаления элемента
      fetchData();
      console.log('Элемент удалён.');
    }
  };

  return (
    <FaPrescriptionBottle title={title} class="cursor-point icon-med" onClick={handleDelete}/>    
  );
}

export default DeleteConfirmation;
