// Modal.jsx
import React from 'react';
import './Modal.scss';

const Modal = ({ isOpen, onClose, children, marginBottom }) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`} onClick={onClose}>
      <div style={marginBottom = {marginBottom}} className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
