import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {Home} from '../../pages/Home'
import {Cabinet} from '../../pages/Cabinet'
import {Questions} from '../../pages/Questions'
import {Answers} from '../../pages/Answers'
import { Stats } from '../../pages/Stats'
import { StatById } from '../../pages/StatById'

const AppRoutes = () => {
  return (
    <Routes>
        <Route index element={<Home/>}/>
        <Route path="/cabinet" element={<Cabinet/>}/>
        <Route path="/questions" element={<Questions/>}/>
        <Route path="/answers" element={<Answers/>}/>
        <Route path="/stats" element={<Stats/>}/>
        <Route path="/stat-id" element={<StatById/>}/>
    </Routes>
  )
}

export default AppRoutes