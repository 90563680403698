import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TimestampComponent from '../TimestampComponent/TimestampComponent';
import { useNavigate } from 'react-router-dom';
import { FaList } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdOutlineNoteAdd } from "react-icons/md";
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import DictationEdit from '../DictationEdit/DictationEdit';

const DictationList = () => {
    const childRef = useRef();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN,
                    'Content-Type': 'application/json'
                }
              }
              let body = JSON.stringify({
                Id: 0,
              })
            const response = await axios.post(global.API_URI+'/dictation/list', body, config);
            setItems(response.data.Data); // Обновляем состояние списка элементов данными из ответа
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    useEffect(() => {
        fetchData(); 
    }, []); 

    const renderList = () => {
        return items != null ? (items.map((item, index) => (
            <tr key={index}>
                <td>
                    {index+1}
                </td>
                <td>
                    {item.Name}
                </td>
                <td>
                    {item.Title}
                </td>
                <td>
                    <TimestampComponent timestamp={item.StartRegister} />
                </td>
                <td>
                    <FaList title="Открыть список вопросов" class="cursor-point icon-med" onClick={()=>{global.DICTATION_ID=item.Id;global.DICTATION_NAME=item.Name; navigate('/questions');}}/>
                    &nbsp;
                    <FaEdit title="Редактировать диктант" class="cursor-point icon-med" onClick={()=>{
                    if (childRef.current) {
                        childRef.current.callChildFunction(
                            {   id:item.Id,
                                name: item.Name,
                                title: item.Title,
                                limit: item.Limit,
                                count: item.QCount,
                                start_register: item.StartRegister,
                                start_test: item.StartTest,
                                end_test: item.EndTest
                            }
                        );
                    }
                    openModal()}}/>
                    &nbsp;&nbsp;&nbsp;
                    <DeleteConfirmation id={item.Id} title="Удалить диктант" type={0}  updateData={fetchData}/>
                </td>
            </tr>
        ))):(<p>Диктантов нет.</p>);
    };
 
    return (
        <div class="table-container">
        <table class="table">
            <thead>
                <tr>
                    <th class="index">ID</th>
                    <th class="name">Имя</th>
                    <th>Название</th>
                    <th class="date">Старт диктанта</th>
                    <th class="control">Управление</th>
                </tr>
            </thead>
            <tbody>
                {renderList()}
            </tbody>
        </table>
        <div className="cursor-point create" onClick={()=>{
                if (childRef.current) {
                            childRef.current.callChildFunction(
                                {   id:0,
                                    name: '',
                                    title: '',
                                    limit: 30,
                                    count: 30,
                                    start_register: '2024-08-30 0:00:00.0+03',
                                    start_test: '2024-09-01 0:00:00.0+03',
                                    end_test: '2024-09-02 0:00:00.0+03'
                                }
                            );
                }
                openModal()
                }}>
            <MdOutlineNoteAdd title="Добавить диктант" class="cursor-point icon-big"/>
            Создать диктант
        </div>
        <DictationEdit ref={childRef} updateList={fetchData} closeModal={closeModal} isModalOpen={isModalOpen}/>
        </div>
    )
 };
 
 export default DictationList;