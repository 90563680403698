import React, {useState, forwardRef, useImperativeHandle} from 'react'
import axios from 'axios';
import Modal from '../Modal/Modal'
import UploadImage from '../UploadImage/UploadImage';

const QuestionEdit = forwardRef((props, ref) => {
  const [response, setResponse] = useState([]);
  const [id, setId] = useState(0);
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(0);
  //const [isCorrect, setCorrect] = useState(false);

  const internalFunction = (data) => {
    setId(data.id)
    setDescription(data.description)
    setImage(data.image)
  };
  
  useImperativeHandle(ref, (data) => ({
    callChildFunction: (data) => internalFunction(data)
  }));
  
  const setImageId = (id) => {
    setImage(id);
  }

  const fetchData = async () => { 
    try {
        let config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+global.TOKEN
            },
          }
        let body = JSON.stringify({
          DictationId: global.DICTATION_ID,
            QuestionId: id,
            Description: description,
            ImageId: image,
            IsEnabled: true
          })
        const response = await axios.post(global.API_URI+(id===0?'/dictation/question-add':'/dictation/question-change'), body, config);
        setResponse(response.data.Data);
    } catch (error) {
        console.error('Ошибка при получении данных:', error);
    }
};

  return (
    <Modal isOpen={props.isModalOpen} onClose={props.closeModal}>
        <div className='title'>
            {id===0?'Создание':'Редактирование'} вопроса.
        </div>
        <div className='description'>
            <textarea name="body"
            placeholder="Содержание вопроса"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
        />
        </div>
        <UploadImage setImageId={setImageId}/>
        <div className='buttons'>
          <button onClick={async ()=>{await fetchData(); await props.updateList(response?.id); props.closeModal()}}>Сохранить</button>
          <button onClick={props.closeModal}>Отмена</button>
        </div>
    </Modal> 
  )
})

export default QuestionEdit