import React, { useState } from 'react';
import axios from 'axios';

const UploadImage = (props) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setError("Please select a file to upload.");
      return;
    }

    setUploading(true);
    setError(null);
    setSuccess(false);

    try {
      const formData = new FormData();
      formData.append("image", file);
      console.log(file.name)
      const response = await axios.post(global.API_URI+"/images/upload", 
          formData, { headers: {
          "Content-Type": "multipart/form-data",'Authorization': 'Bearer '+global.TOKEN}});

      if (response.status === 200) {
        setSuccess(true);
        props.setImageId(response.data.Id)
        setTimeout(() => {
          setFile(null);
          setPreview(null);
          setSuccess(false);
        }, 2000);
      }
    } catch (err) {
      setError("Failed to upload image.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="image-upload">
      <form onSubmit={handleSubmit}>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        {preview && <img src={preview} alt="Preview" style={{ width: "100px", height: "100px" }} />}
        <button type="submit" disabled={uploading}>
          {uploading ? "Загрузка..." : "Загрузить изображение"}
        </button>
      </form>
      {success && <p>Изображение загружено!</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default UploadImage