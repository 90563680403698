import React from 'react'
import DictationList from '../components/DictationList/DictationList'
import { useNavigate } from 'react-router-dom'

export const Cabinet = () => {
    const navigate = useNavigate();
    return (
      <div className='container'>
      {global.TOKEN && global.ROLE===1 ? (
        <div>
          <div className='stats-link' onClick={()=>navigate('/stats')}>Статистика</div>
          <div className='title'>Список диктантов</div>
          <DictationList/>
        </div>
      ) : (
        <p>Пожалуйста, войдите, чтобы увидеть содержимое.</p>
      )}
      </div>
    )
}


