import { format, parseISO } from 'date-fns';

const TimestampComponent = ({ timestamp, type }) => {
    try{
        let formattedDate = format(parseISO(timestamp), 'dd.MM.yyyy');
        switch(type){
        case 1:
            formattedDate = format(parseISO(timestamp), 'dd.MM-hh:mm')
            break
        case 2:
            formattedDate = format(parseISO(timestamp), 'hh:mm')
            break
            case 3:
                formattedDate = format(parseISO(timestamp), 'hh:mm:ss')
                break
            default:
                formattedDate = format(parseISO(timestamp), 'dd.MM.yyyy');
        }

        return <div>{formattedDate}</div>;
    }catch{
        return <div></div>;
    }
};

export default TimestampComponent;