import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaList } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdOutlineNoteAdd } from "react-icons/md";
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import QuestionEdit from '../QuestionEdit/QuestionEdit';
import ImageViwer from '../ImageViwer/ImageViwer';

const QuestionList = () => {
    const childRef = useRef();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    
    const fetchData = async () => {
        try {
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN
                },
              }
            let body = JSON.stringify({
                Id: global.DICTATION_ID,
              })
            const response = await axios.post(global.API_URI+'/dictation/question-list', body, config);
            setItems(response.data.Data);
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    useEffect(() => {
        fetchData(); 
    }, []); 

    const renderList = () => {
        return items != null ? (items.map((item, index) => (
            <tr key={index}>
                <td>
                    {index+1}
                </td>
                <td>
                    <ImageViwer id={item.ImageId} />
                </td>
                <td>
                    {item.Description}
                </td>
                <td>
                <FaList title="Список ответов" class="cursor-point icon-med" onClick={()=>{global.QUESTION_ID=item.QuestionId;global.QUESTION_DESCRIPTION=item.Description;global.QUESTION_IMAGE_ID = item.ImageId; navigate('/answers');}}/>
                &nbsp;
                <FaEdit title="Редактировать вопрос" class="cursor-point icon-med" onClick={()=>{
                    if (childRef.current) {
                        childRef.current.callChildFunction(
                            {   id:item.QuestionId,
                                description: item.Description,
                                image: item.ImageId}
                        );
                    }
                    openModal()}}/>
                &nbsp;&nbsp;&nbsp;
                <DeleteConfirmation id={item.QuestionId} title="Удалить вопрос" type={1}  updateData={fetchData}/>  
                </td>
            </tr>
        ))):(<p>Вопросов нет.</p>);
    };
 
    return (
        <div class="table-container">
        <div class="back-button"
            onClick={()=>{navigate('/cabinet');}}>Назад</div>
        <table class="table">
            <thead>
                <tr>
                    <th  class="index">ID</th>
                    <th class="photo">Фото</th>
                    <th>Вопрос</th>
                    <th class="control">Управление</th>
                </tr>
            </thead>
            <tbody>
                {renderList()}
            </tbody>
        </table>

        <div className="cursor-point create" onClick={()=>{
                if (childRef.current) {
                            childRef.current.callChildFunction(
                                {   id:0,
                                    description: '',
                                    image: 0}
                            );
                }
                openModal()
                }}>
            <MdOutlineNoteAdd title="Добавить вопрос" class="cursor-point icon-big"/>
            Создать вопрос
        </div>
        <QuestionEdit ref={childRef} updateList={fetchData} closeModal={closeModal} isModalOpen={isModalOpen}/>
        </div>
    )
 };
 
 export default QuestionList;