import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TimestampComponent from '../TimestampComponent/TimestampComponent';
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { FaFileDownload } from "react-icons/fa";

const StatDictationList = () => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN,
                    'Content-Type': 'application/json'
                }
              }
            const response = await axios.post(global.API_URI+'/stats/list', null, config);
            setItems(response.data.Data); // Обновляем состояние списка элементов данными из ответа
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    const fetchCSV = async () => {
        try {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN,
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
              }
            const response = await axios.post(global.API_URI+'/stats/list-csv', null, config);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'list.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    useEffect(() => {
        fetchData(); 
    }, []); 

    const renderList = () => {
        return items != null ? (items.map((item, index) => (
            <tr key={index}>
                <td>
                    {item.Id}
                </td>
                <td>
                    {item.Title}
                </td>
                <td>
                    <TimestampComponent timestamp={item.Start} type={0}/>
                </td>
                <td>
                    <TimestampComponent timestamp={item.Start} type={2}/>
                </td>
                <td>
                    <TimestampComponent timestamp={item.End} type={2}/>
                </td>
                <td>
                {item.School}
                </td>
                <td>
                    {item.Position}
                </td>
                <td>
                {item.Lastname + ' ' + item.Firstname}
                </td>
                <td>
                    {item.Count===0?"":item.Count}
                </td>
                <td>
                    {item.Correct===0?"":item.Correct}
                </td>
                <td>
                    {item.NotPassed===0?"":item.NotPassed}
                </td>
                <td>
                    <FaInfoCircle className='icon-med cursor-point' onClick={()=>{
                        global.DICTATION= { 
                            id:item.Id,
                            title:item.Title,
                            fio: item.Lastname + ' ' + item.Firstname,
                            count: item.Count,
                            correct: item.Correct,
                            not: item.NotPassed,
                            school: item.School,
                            position: item.Position,
                            start: item.Start,
                            end: item.End
                        }; navigate('/stat-id');}}/>
                </td>
            </tr>
        ))):(<p>Диктантов нет.</p>);
    };
 
    return (
        <div class="table2-container">
            <div style={{textAlign:"right"}}>
            <FaFileDownload className='icon-med cursor-point' onClick={()=>fetchCSV()}/>
            </div>
        <table class="table">
            <thead>
                <tr>
                    <th class="index">ID</th>
                    <th class="title">Название</th>
                    <th class="date1">Дата</th>
                    <th class="date2">Старт</th>
                    <th class="date2">Конец</th>
                    <th>Школа</th>
                    <th className="counters">Класс</th>
                    <th>ФИО</th>
                    <th class="counters">Всего</th>
                    <th class="counters">Правильно</th>
                    <th class="counters">Без ответа</th>
                    <th class="counters">Детали</th>
                </tr>
            </thead>
            <tbody>
                {renderList()}
            </tbody>
        </table>
    
        </div>
    )
 };
 
 export default StatDictationList;