import React, {useState, forwardRef, useImperativeHandle} from 'react'
import axios from 'axios';
import Modal from '../Modal/Modal'
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

const DictationEdit = forwardRef((props, ref) => {
  const [response, setResponse] = useState([]);
  const [id, setId] = useState(0);
  const [d_name, setDName] = useState('');
  const [title, setTitle] = useState('');
  const [limit, setLimit] = useState(0);
  const [q_count, setQCount] = useState(0);
  const [start_register, setStart] = useState('2024-08-30 0:00:00.0+03');
  const [start_test, setStartTest] = useState('2024-09-01 0:00:00.0+03');
  const [end_test, setEndTest] = useState('2024-09-02 0:00:00.0+03');

  const handleLimitChange = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || /^\d+$/.test(newValue)) {
      setLimit(newValue);
    }
  };

  const handleQCountChange = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || /^\d+$/.test(newValue)) {
      setQCount(newValue);
    }
  };

  const formatDate = (timestamp) => {
    const formattedTimestamp = timestamp.replace(' 0:00:00', ' 00:00:00').replace(' ', 'T').replace('.0+03','.000Z');
    const date = toZonedTime(formattedTimestamp, 'Europe/Moscow');
    return format(date, "d MMMM yyyy 'года' H:mm 'МСК'", { locale: ru });
  };

  //const [isCorrect, setCorrect] = useState(false);

  const internalFunction = (data) => {
    setId(data.id)
    setDName(data.name)
    setTitle(data.title)
    setLimit(data.limit)
    setQCount(data.count)
    setStart(data.start_register)
    setStartTest(data.start_test)
    setEndTest(data.end_test)
  };
  
  useImperativeHandle(ref, (data) => ({
    callChildFunction: (data) => internalFunction(data)
  }));

  const fetchData = async () => { 
    try {
        let config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+global.TOKEN
            },
          }
        let body = JSON.stringify({
            Id: id,
            Name: d_name,
            Title: title,
            Limit: parseInt(limit, 30),
            QCount: parseInt(q_count),
            Status: true,
            StartRegister: start_register,
            StartTest: start_test,
            EndTest: end_test,
          })
        const response = await axios.post(global.API_URI+(id===0?'/dictation/add':'/dictation/change'), body, config);
        setResponse(response.data.Data);
    } catch (error) {
        console.error('Ошибка при получении данных:', error);
    }
};

  return (
    <Modal isOpen={props.isModalOpen} onClose={props.closeModal}>
        <div className='title'>
            {id===0?'Создание':'Редактирование'} диктанта.
        </div>
        <div className='table '>
          <div className='column first-column'>Имя диктанта</div> 
          <div className='column second-column'>
            <input
            placeholder="Имя диктанта"
            value={d_name}
            onChange={(e) => setDName(e.target.value)}
          />
          </div>
        </div>
        
        <div className='table'>
          <div className='column first-column'>
          Название диктанта</div>
          <div className='column second-column'><input
            placeholder="Название диктанта"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          </div>
        </div>

        <div className='table'>
        <div className='column first-column'>
          Лимит по времени(мин)</div>
          <div className='column second-column'><input
            placeholder="Лимит по времени в минутах"
            value={limit}
            onChange={handleLimitChange}
          />
          </div>
        </div>
        <div className='table'>
        <div className='column first-column'>
          Число вопросов </div>
          <div className='column second-column'><input
            placeholder="Число вопросов для тестируемого"
            value={q_count}
            onChange={handleQCountChange}
          />
          </div>
        </div>
        <div className='table'>
        <div className='column first-column'>Старт регистрации </div>
        <div className='column second-column'>{formatDate(start_register)}</div>
        </div>
        <div className='table'>
        <div className='column first-column'>Старт теста </div>
        <div className='column second-column'>{formatDate(start_test)}</div>
        </div>
        <div className='table'>
        <div className='column first-column'>Окончание теста</div>
        <div className='column second-column'>{formatDate(end_test)}</div>
        </div>
        
        <div className='buttons'>
          <button onClick={async ()=>{await fetchData(); await props.updateList(response?.id); props.closeModal()}}>Сохранить</button>
          <button onClick={props.closeModal}>Отмена</button>
        </div>
    </Modal> 
  )
})

export default DictationEdit