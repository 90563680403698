import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaEdit } from "react-icons/fa";
import { MdOutlineNoteAdd } from "react-icons/md";
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import { FaCheck } from "react-icons/fa6";
import AnswerEdit from '../AnswerEdit/AnswerEdit';
import ImageViwer from '../ImageViwer/ImageViwer';

const AnswerList = () => {
    const childRef = useRef();
    const [items, setItems] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const fetchData = async () => { 
        try {
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN
                },
              }
            let body = JSON.stringify({
                Id: global.QUESTION_ID,
              })
            const response = await axios.post(global.API_URI+'/dictation/answer-list', body, config);
            setItems(response.data.Data);
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Вызываем функцию при монтировании компонента
    }, []); // Пустой массив зависимостей, чтобы эффект выполнился один раз

    const renderList = () => {
        return items != null ? (items.map((item, index) => (
            <tr key={index}>
                <td>
                    {index+1}
                </td>
                <td>
                    {item.IsCorrect ? <FaCheck class="cursor-point icon-med" />: null }
                </td>
                <td>
                    <ImageViwer id={item.ImageId} />
                </td>
                <td>
                    {item.Description}
                </td>
                <td>
                <FaEdit title="Редактировать ответ" class="cursor-point icon-med" onClick={()=>{
                    if (childRef.current) {
                        childRef.current.callChildFunction(
                            {   id:item.AnswerId,
                                description: item.Description,
                                image: item.ImageId,
                                correct: item.IsCorrect}
                        );
                    }
                    openModal()}}/>
                &nbsp;&nbsp;&nbsp;
                <DeleteConfirmation id={item.AnswerId} title="Удалить ответ" type={2} updateData={fetchData}/>  
                </td>
            </tr>
        ))):(<p>Ответов нет.</p>);
    };
 
    return (
        <div class="table-container">
        <table class="table">
            <thead>
                <tr>
                    <th  class="index">ID</th>
                    <th  class="result"></th>
                    <th class="photo">Фото</th>
                    <th>Вопрос</th>
                    <th class="control">Управление</th>
                </tr>
            </thead>
            <tbody>
                {renderList()}
            </tbody>
        </table>
        <div className="cursor-point create"  onClick={()=>{
                if (childRef.current) {
                            childRef.current.callChildFunction(
                                {   id:0,
                                    description: '',
                                    image: 0,
                                    correct: false}
                            );
                }
                openModal()}}>
        <MdOutlineNoteAdd title="Добавить ответ" class="icon-big"/>
            Создать ответ
        </div>
        <AnswerEdit ref={childRef} updateList={fetchData} closeModal={closeModal} isModalOpen={isModalOpen}/>
        </div>
    )
 };
 
 export default AnswerList;