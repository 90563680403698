import React from 'react'
import { useNavigate } from 'react-router-dom'
import StatDictationId from '../components/StatDictationId/StatDictationId';
import TimestampComponent from '../components/TimestampComponent/TimestampComponent';

export const StatById = () => {
    const navigate = useNavigate();
    return (
      <div className='container'>
      {global.TOKEN && global.ROLE===1 ? (
        <div>
          <div className='stats-link' onClick={()=>navigate('/stats')}>Назад к статистике</div>
          <div className='title'>Прохождение диктанта</div>
          <div className='title'>{global.DICTATION.title}</div>
          <div className='title1'>{global.DICTATION.school}, {global.DICTATION.position}</div>
          <div className='title'>{global.DICTATION.fio}</div>
          <div className='title1'>
            Период прохождения
            <TimestampComponent timestamp={global.DICTATION.start} type={0}/>
            <TimestampComponent timestamp={global.DICTATION.start} type={3}/>
            <TimestampComponent timestamp={global.DICTATION.end} type={3}/>
          </div>
          
          <StatDictationId/>
          <div className='title'>Результаты</div>
          <div className='title1'>Всего вопросов: {global.DICTATION.count}</div>
          <div className='title1'>Верных вопросов: {global.DICTATION.correct}</div>
          <div className='title1'>Без ответа: {global.DICTATION.not}</div>
        </div>
      ) : (
        <p>Пожалуйста, войдите, чтобы увидеть содержимое.</p>
      )}
      </div>
    )
}


