import React from 'react'
import QuestionList from '../components/QuestionList/QuestionList'
import { FaBackwardStep } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

export const Questions = () => {
    const navigate = useNavigate();
    return (
      <div>
      {global.TOKEN && global.ROLE===1 ? (
        <div className='container'>
            <div className='title'>Диктант</div>
            <div className='description'>{global.DICTATION_NAME}</div>
            <div className='back-nav' onClick={()=>{ navigate('/cabinet');}}>
              <span className='text'>
                <FaBackwardStep size={28} title="Вернуться к списку диктантов" class="cursor-point"/>
              </span>
              <span className='text'></span>
            </div>
            <div className='title'>Список вопросов</div>
            <QuestionList/>
        </div>
      ) : (
        <p>Пожалуйста, войдите, чтобы увидеть содержимое.</p>
      )}
      </div>
    );
}
