import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TimestampComponent from '../TimestampComponent/TimestampComponent';
import { FaFileDownload } from "react-icons/fa";

const StatDictationId = () => {
    const [items, setItems] = useState([]);


    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN,
                    'Content-Type': 'application/json'
                }
              }
            let body = JSON.stringify({
                Id: global.DICTATION.id,
            })
            const response = await axios.post(global.API_URI+'/stats/get-by-id', body, config);
            setItems(response.data.Data); 
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    const fetchCSV = async () => {
        try {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+global.TOKEN,
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
              }

            let body = JSON.stringify({
                Id: global.DICTATION.id,
            })
            const response = await axios.post(global.API_URI+'/stats/get-by-id-csv', body, config);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'd-'+global.DICTATION.id+'.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    useEffect(() => {
        fetchData(); 
    }, []); 

    const renderList = () => {
        return items != null ? (items.map((item, index) => (
            <tr key={index}>
                <td>
                <TimestampComponent timestamp={item.CheckedDate} type={3}/>
                </td>
                <td>
                    {item.GAnswer}
                </td>
                <td>
                    {item.Answer}
                </td>
                <td>
                    {item.Correct===true?'Верно':''}
                </td>
                <td>
                    {item.Description}
                </td>
            </tr>
        ))):(<p>Диктантов нет.</p>);
    };
 
    return (
        <div class="table2-container">
        <div style={{textAlign:"right"}}>
            <FaFileDownload className='icon-med cursor-point' onClick={()=>fetchCSV()}/>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th class="date">Отмечен</th>
                    <th class="title">Ответ</th>
                    <th class="title">Правильный ответ</th>
                    <th class="counters">Результат</th>
                    <th>Вопрос</th>
                </tr>
            </thead>
            <tbody>
                {renderList()}
            </tbody>
        </table>
    
        </div>
    )
 };
 
 export default StatDictationId;