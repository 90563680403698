import React from 'react'
import LoginForm from '../components/LoginForm/LoginForm'

export const Home = () => {
  
  return (
      <LoginForm/>
  )
}

 