import React from 'react'
import AnswerList from '../components/AnswerList/AnswerList'
import { FaBackwardStep } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import ImageViwer from '../components/ImageViwer/ImageViwer';

export const Answers = () => {
    const navigate = useNavigate();
    return (
      <div className='container'> 
      {global.TOKEN && global.ROLE===1 ? (
        <div>
            <div className='title'>Вопрос</div>
            <div className='image-center'>
              <ImageViwer id={global.QUESTION_IMAGE_ID} />
            </div>
            <div className='description'>
              {global.QUESTION_DESCRIPTION}
            </div>
            <div className='back-nav'>
              <span className='text'>
                <FaBackwardStep size={28} title="Вернуться к списку вопросов" class="cursor-point" onClick={()=>{ navigate('/questions');}}/>
              </span>
              <span className='text'>
                
              </span>
            </div>
            <AnswerList/>
        </div>
      ) : (
        <p>Пожалуйста, войдите, чтобы увидеть содержимое.</p>
      )}
      </div>
    );
}
