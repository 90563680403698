import React from 'react'
import { useNavigate } from 'react-router-dom'
import StatDictationList from '../components/StatDictationList/StatDictationList';

export const Stats = () => {
    const navigate = useNavigate();
    return (
      <div className='container'>
      {global.TOKEN && global.ROLE===1 ? (
        <div>
          <div className='stats-link' onClick={()=>navigate('/cabinet')}>Диктанты</div>
          <div className='title'>Список прохождений</div>
          <StatDictationList/>
        </div>
      ) : (
        <p>Пожалуйста, войдите, чтобы увидеть содержимое.</p>
      )}
      </div>
    )
}


